<template>
	<div class="pa-3 full-height new-back">

		<filter-search-input v-model="filters.search" @input="handleSearch" :applied-filters="appliedFilters" outlined dense>
			<template #append>
				<v-tooltip v-if="$security.can('add')" top z-index="200">
					<template #activator="{ on }">
						<v-btn v-on="on" fab color="primary" small to="/admin/billable/new">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</template>
					<span class="caption">Add new</span>
				</v-tooltip>
			</template>
			<v-select
				v-model="filters.status" :items="statuses"
				@change="fetchBillables"
				label="Status" item-value="id" item-text="id"
				dense outlined hide-details clearable input-select multiple hide-input
			>
				<template #item="{ item }">
					<v-btn
						:color="item.color"
						outlined small style="border-radius: 20px"
						:style="{
							background: $vuetify.theme.isDark ? '' : item.lightColor
						}"
					>{{item.description}}</v-btn>
				</template>
				<template #selection="{ item }">
					<v-btn
						:color="item.color"
						outlined small style="border-radius: 20px"
						:style="{
							background: $vuetify.theme.isDark ? '' : item.lightColor
						}"
					>{{item.description}}</v-btn>
				</template>
			</v-select>
			<v-autocomplete
				v-model="filters.customer" :items="customers"
				@change="changeCustomer"
				label="Customer"
				dense outlined hide-details clearable multiple
				item-text="name" item-value="id"
			/>
			<v-autocomplete
				v-model="filters.project" :items="projects"
				@change="fetchBillables"
				label="Project"
				dense outlined hide-details clearable multiple
				item-text="name" item-value="id"
			/>
			<v-select
				v-model="filters.currency" :items="currencies"
				@change="fetchBillables"
				label="Currency"
				dense outlined hide-details clearable input-select multiple
				item-text="text" item-value="text"
			/>
			<v-select
				v-model="filters.type" :items="types"
				@change="fetchBillables"
				label="Type"
				dense outlined hide-details clearable input-select multiple
				item-text="text" item-value="value"
				:parent-shown-items="billables"
				:data-comparer="(item, value) => item.type == value"
			/>
			<v-select
				v-model="filters.openBillable" :items="openBillables"
				@change="fetchBillables"
				label="Billables"
				dense outlined hide-details clearable
				item-text="text" item-value="value"
			/>
		</filter-search-input>

		<billables-table
			:billables="billables"
			:item-class="() => $security.can('detail') ? 'cursor-pointer' : ''"
			:pagination.sync="pagination"
			@click:row="(item, _, e) => $security.can('detail') ? $q.goTo('/admin/billable/details/' + item.id, e) : null"
			@validate="validateBillable"
			@cancel="cancelBillable"
			@duplicate="duplicate"
			@updated="fetchBillables"
			@pagination="fetchBillables"
		/>
	</div>
</template>

<script>
import BillableJSON from '../../json/billable.json'
import BillablesTable from '../../components/BillablesTable'

export default {
	components: { BillablesTable },
	props: ['customerId', 'currency'],
	data(){
		return {
			billables: [],
			holidays: [],
			openBillables: [
				{ value: true, text: 'Open billable' },
        { value: false, text: 'Without open billable' },
			],
			filters: {
				status: [],
				customer: [],
				project: [],
				currency: [],
				type: [],
				openBillable: null
			},
			searchTimeout: null,
			statuses: [],
			currencies: [
				{text: 'MXN'},
				{text: 'USD'},
				{text: 'EUR'},
			],
			customers: [],
			projects: [],
			projectTypes: [],
			types: [
				{ text: 'Fixed Rate', value: 0, },
				{ text: 'Rate per Assigned Hour', value: 1, },
				{ text: 'Rate per Executed Hour', value: 2, },
				{ text: 'Internal', value: 3, },
			],
			currentUser: {},
			pagination: {
				page: 1,
				itemsPerPage: 10,
				totalItems: 0,
				sortBy: '',
				sortDesc: false,
			},
		}
	},
	created(){
		document.title = 'Billables'
		this.setUserPreferences("Billables", "filters")
    this.$q.log(4, 23, 3, 'NA')
		this.projectTypes = BillableJSON.projectTypes

		if(this.customerId){
			this.filters = {
				status: [1, 2, 3, 4, 5, 6],
				customer: [],
				project: [],
				currency: [],
			}
			if(this.currency)
		  	this.filters.currency.push(this.currency)
		  this.filters.customer.push(this.customerId)
		}

		this.init()
	},
	computed: {
		appliedFilters(){
			return this.countActiveFilters(this.filters, ['search'])
		},
	},
	methods: {
		async init(){
			await this.$blueSystem.getCurrentUser()
        .then(user => {
          this.currentUser = user
        })
				.catch(error => this.$root.error('Failed to get user'))
			this.$http.get("/api/customer?onlyWithCustomerProjects=true")
				.then(res => {
					this.customers = _.orderBy(res.data, ['name'])

					for(let customer of this.customers){
						for(let project of customer.customerProjects){
							if(!this.projects.some(p => p.id == project.id)){
								this.projects.push(project)
							}
						}
					}
					this.projects = _.orderBy(this.projects, ['name'])
				})
				.catch(res => {
					console.error(res)
					window.getApp.error("Cannot obtain customers.")
				})
			
				this.$http.get('api/enums/admin/billable/status')
					.then(res => {
						this.statuses = res.data
					})
					.catch(res => {
						console.error(res)
						window.getApp.error("Cannot obtain billable statuses.")
					})
		},
		fetchBillables(){
			let sortBy = Array.isArray(this.pagination.sortBy) ? this.pagination.sortBy[0] : this.pagination.sortBy
			let sortDesc = Array.isArray(this.pagination.sortDesc) ? this.pagination.sortDesc[0] : this.pagination.sortDesc

			this.$http.post(
				'api/billable/detailed',
				{
					search: this.filters.search,
					status: this.filters.status,
					customerId: this.filters.customer,
					customerProjectId: this.filters.project,
					currency: this.filters.currency,
					type: this.filters.type,
					openBillable: this.filters.openBillable,
					
					page: this.pagination.page === 'all' ? -1 : this.pagination.page,
					pageSize: this.pagination.itemsPerPage,
					sortBy: sortBy,
					sortDesc: sortDesc,
				}
			)
				.then(res => {
					this.billables = res.data.billables
						.map(x => ({
							...x,
							canApprove: x.approvers
								.some(y =>
									!x.isDraft && !x.isApproved && !x.isCancelled &&
									y.approverId == this.currentUser.id &&
									y.statusToApprove === 1 &&
									y.status === 0
								)
						}))
					this.pagination.totalItems = res.data.total

					if(this.billables.length == 0 && this.pagination.totalItems){
						this.pagination.page = 1
					}
				})
				.catch(res => {
					console.error(res)
					this.$root.error("Cannot obtain billables.")
				})
		},
		handleSearch(){
			clearTimeout(this.searchTimeout)
			this.searchTimeout = setTimeout(this.fetchBillables, 500)
		},
		duplicate(id){
      this.$router.push({ path: '/admin/billable/new', query: { billableBase: id }})
		},
		changeCustomer(){
			this.filters.project = null
			this.fetchBillables()
		},
		cancelBillable(item){
			this.$adminSurf('cancelBillable', item.id)
				.then(res => {
					if(res){
						this.init()
					}
				})
				.catch(res => {
					console.log(res)
					this.$root.error('Cannot delete billable')
				})
		},
		validateBillable(item){
			this.$adminSurf('validateBillable', item)
				.then(res => {
					this.fetchBillables()
					this.$root.success('Billable marked as approved')
				})
				.catch(res => {
					console.log(res)
					this.$root.error('Cannot validate billable')
				})
		},
		addComment(item, comment){
			item.changes.push(comment)
		},
	},
}
</script>

<style lang="scss" scoped>
</style>